export const timeheart = {
  title: {
    show: true,
    text: "靶心率运动时间",
    left: "center", // center horizontally
    bottom: "bottom",
    textStyle: {
      fontWeight: "normal",
      fontSize: "14",
    },
  },
  tooltip: {
    trigger: "item",
    show: false,
  },
  legend: {
    top: "5%",
    left: "center",
    show: false,
  },
  series: [
    {
      name: "Access From",
      type: "pie",
      radius: ["40%", "70%"],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 10,
        borderColor: "#fff",
        borderWidth: 2,
      },
      label: {
        show: true,
        position: "center",
        fontSize: 20,
        fontWeight: "normal",
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 20,
          fontWeight: "normal",
        },
      },
      labelLine: {
        show: false,
      },
      data: [{ value: 1048, name: "--分钟" }],
    },
  ],
};

export const averageheart = {
  title: {
    text: "Stacked Line",
    show: false,
  },
  tooltip: {
    trigger: "axis",
  },
  legend: {
    data: ["全班平均心率", "男生平均心率", "女生平均心率"],
  },
  grid: {
    left: "3%",
    right: "4%",
    bottom: "8%",
    containLabel: false,
  },
  toolbox: {},
  xAxis: {
    type: "category",
    boundaryGap: false,
    data: [],
  },
  dataZoom: [
    {
      orient: "horizontal",

      show: true, //控制滚动条显示隐藏

      realtime: true, //拖动滚动条时是否动态的更新图表数据

      height: 15, //滚动条高度

      start: 0, //滚动条开始位置（共100等份）

      end: 10, //滚动条结束位置

      bottom: "4%",

      zoomLock: true, //控制面板是否进行缩放
    },

    {
      type: "inside",

      brushSelect: true,

      start: 0,

      end: 100,

      xAxisIndex: [0],
    },
  ],
  yAxis: {
    type: "value",
  },
  color: ["#1890FF", "#61D2B3", "#FF139C"],
  series: [
    {
      name: "全班平均心率",
      type: "line",
      stack: "Total",
      data: [],
    },
    {
      name: "男生平均心率",
      type: "line",
      stack: "Total",
      data: [],
    },
    {
      name: "女生平均心率",
      type: "line",
      stack: "Total",
      data: [],
    },
  ],
};

export const singleaverageheart = {
  title: {
    text: "Stacked Line",
    show: false,
  },
  tooltip: {
    trigger: "axis",
  },
  legend: {
    data: ["全班平均心率", "个人平均心率"],
  },
  grid: {
    left: "3%",
    right: "4%",
    bottom: "8%",
    containLabel: false,
  },
  toolbox: {},
  xAxis: {
    type: "category",
    boundaryGap: false,
    data: ["值1", "值1", "值1", "值1", "值1", "值1", "值1"],
  },
  yAxis: {
    type: "value",
  },
  dataZoom: [
    {
      orient: "horizontal",

      show: true, //控制滚动条显示隐藏

      realtime: true, //拖动滚动条时是否动态的更新图表数据

      height: 15, //滚动条高度

      start: 0, //滚动条开始位置（共100等份）

      end: 10, //滚动条结束位置

      bottom: "4%",

      zoomLock: true, //控制面板是否进行缩放
    },

    {
      type: "inside",

      brushSelect: true,

      start: 0,

      end: 100,

      xAxisIndex: [0],
    },
  ],
  color: ["#1890FF", "#61D2B3", "#FF139C"],
  series: [
    {
      name: "全班平均心率",
      type: "line",
      stack: "Total",
      data: [120, 132, 101, 134, 90, 230, 210],
    },
    {
      name: "个人平均心率",
      type: "line",
      stack: "Total",
      data: [220, 182, 191, 234, 290, 330, 310],
    },
  ],
};
