import { baseUrl } from "@/utils/config";
import request from "@/utils/request";
// 课堂班级报告
export function reportSchedule(data) {
  return request({
    url: baseUrl + "/report/schedule",
    method: "get",
    params: data,
  });
}
// 课堂报告（第三部分 单个学生数据）
export function scheduleStudent(data) {
  return request({
    url: baseUrl + "/report/scheduleStudent",
    method: "get",
    params: data,
  });
}

// 课堂班级报告  平均心率图
export function classGraph(data) {
  return request({
    url: baseUrl + "/report/classGraph",
    method: "get",
    params: data,
  });
}

// 课堂报告(学生)第一部分
export function studentReport(data) {
  return request({
    url: baseUrl + "/report/studentReport",
    method: "get",
    params: data,
  });
}

// 课堂报告(学生)第二部分
export function studentclassGraph(data) {
  return request({
    url: baseUrl + "/report/classGraph",
    method: "get",
    params: data,
  });
}
// 课堂报告(学生)第三部分
export function reportscheduleStudent(data) {
  return request({
    url: baseUrl + "/report/scheduleMe",
    method: "get",
    params: data,
  });
}
